import React from 'react';
import { classNames } from "../../helpers/classNames";
import Button from "../form/Button";

const PlanPricingTable = ({ data }) => {
  return (
    <>
      <div className="flex items-end w-full flex-wrap sm:flex-nowrap">
        {data.map((item, index) => (
          <div key={index} className={classNames(
            "relative w-full bg-white px-8 lg:px-10 overflow-hidden rounded-2xl sm:first:rounded-r-none sm:last:rounded-l-none transition-all duration-300",
            item.tag ? "z-10 pt-16 pb-10 shadow-2xl my-5 sm:my-0 sm:-mx-5 sm:hover:scale-105" : "py-8 shadow-lg sm:scale-95 sm:hover:scale-100"
          )}>
            {item.tag && <div className="text-center text-white font-semibold text-xs uppercase absolute bg-mountain-meadow-500 py-1 px-10 -rotate-45 top-7 -left-9">{item.tag}</div>}
            <div className="text-xl font-bold text-center text-cyprus-500">{item.name}</div>
            <div className="flex items-end justify-center py-5 !leading-none">
              <div className="font-MPlusRounded1c text-3xl lg:text-4xl font-bold">
                <sup className="text-lg font-medium -mr-2"><i className="fa-solid fa-dollar-sign"></i></sup> {item.amount}
              </div>
              <div className="text-base lg:text-lg text-slate-400">/{item.terms}</div>
            </div>
            <div className="relative pt-6 space-y-3">
              {item.features?.map((subitem, index) => (
                <div className="flex justify-between items-center" key={index}>
                  <div className="w-full flex-grow flex-shrink text-sm text-slate-600 font-medium">{subitem.name}</div>
                  <div className={classNames(
                    "w-8 flex justify-end flex-grow-0 flex-shrink-0 text-xl",
                    subitem.availability ? "text-pistachio-500" : "text-slate-300"
                  )}>
                    <i className="fa-solid fa-fw fa-check-circle"></i>
                  </div>
                </div>
              ))}
            </div>
            <div className="relative pt-10">
              <Button
                buttonClasses={'w-full'}
                buttonHasLink={false}
                buttonFunction={item.click}
                buttonLabel={"Subscribe"}
                buttonLabelClasses={"uppercase font-bold text-sm"}
                buttonEffect={"filled"}
              />
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default PlanPricingTable;