import React from 'react';

const FaqSkeleton = () => {
  return (
    <section className="relative w-full lg:py-20 bg-slate-100 z-0 min-h-[800px]" id="faq">
      <div className="relative w-full px-0 lg:max-w-4xl xl:max-w-6xl 2xl:max-w-7xl 3xl:max-w-9xl mx-auto">
        <div className="lg:grid lg:grid-cols-5 lg:gap-10">
          <div className="lg:col-span-2">
            <div className="sticky top-28 h-[500px] rounded-b-3xl lg:rounded-3xl shadow overflow-hidden">
              <div className="absolute top-0 left-0 w-full h-full z-0 bg-white animate-pulse flex p-3">
                <div className="w-full bg-slate-100 animate-pulse rounded-3xl"></div>
              </div>
              <div className="absolute top-0 left-0 w-full h-full z-[1] pt-10 pb-40 lg:pb-10 px-10 space-y-4 flex flex-col justify-end">
                <div className="w-3/4 h-4 bg-slate-200 animate-pulse rounded"></div>
                <div className="w-3/4 h-4 bg-slate-200 animate-pulse rounded"></div>
                <div className="space-y-2">
                  <div className="w-full h-3 bg-white animate-pulse rounded"></div>
                  <div className="w-full h-3 bg-white animate-pulse rounded"></div>
                </div>
              </div>
            </div>
          </div>
          <div className="lg:col-span-3 px-5 py-10 lg:p-0 bg-slate-100 lg:bg-transparent -mt-24 lg:mt-0 relative z-10 rounded-t-5xl lg:rounded-none">
            <div className="space-y-4">
              <div className="relative bg-white rounded-lg overflow-hidden shadow border border-slate-100">
                <div className="w-full flex items-center py-4 px-5 border-none bg-transparent space-x-4">
                  <div className="w-full flex-grow flex-shrink space-y-2">
                    <div className="w-full h-3 bg-slate-100 animate-pulse rounded"></div>
                    <div className="w-full h-3 bg-slate-100 animate-pulse rounded"></div>
                  </div>
                  <div className="w-8 h-8 flex-grow-0 flex-shrink-0 ml-auto rounded-md  transition-all duration-200 overflow-hidden flex items-center justify-center bg-slate-100 animate-pulse"></div>
                </div>
                <div className="px-5 border-slate-100 overflow-hidden transition-all duration-200 max-h-[2000px] py-6 border-t opacity-100 visible">
                  <div className="space-y-2">
                    <div className="w-full h-2 bg-slate-100 animate-pulse rounded"></div>
                    <div className="w-full h-2 bg-slate-100 animate-pulse rounded"></div>
                    <div className="w-full h-2 bg-slate-100 animate-pulse rounded"></div>
                    <div className="w-full h-2 bg-slate-100 animate-pulse rounded"></div>
                    <div className="w-full h-2 bg-slate-100 animate-pulse rounded"></div>
                    <div className="w-full h-2 bg-slate-100 animate-pulse rounded"></div>
                  </div>
                </div>
              </div>
              <div className="relative bg-white rounded-lg overflow-hidden shadow border border-slate-100">
                <div className="w-full flex items-center py-4 px-5 border-none bg-transparent space-x-4">
                  <div className="w-full flex-grow flex-shrink space-y-2">
                    <div className="w-full h-3 bg-slate-100 animate-pulse rounded"></div>
                    <div className="w-full h-3 bg-slate-100 animate-pulse rounded"></div>
                  </div>
                  <div className="w-8 h-8 flex-grow-0 flex-shrink-0 ml-auto rounded-md  transition-all duration-200 overflow-hidden flex items-center justify-center bg-slate-100 animate-pulse"></div>
                </div>
                <div className="px-5 border-slate-100 overflow-hidden transition-all duration-200 max-h-[2000px] py-6 border-t opacity-100 visible">
                  <div className="space-y-2">
                    <div className="w-full h-2 bg-slate-100 animate-pulse rounded"></div>
                    <div className="w-full h-2 bg-slate-100 animate-pulse rounded"></div>
                    <div className="w-full h-2 bg-slate-100 animate-pulse rounded"></div>
                    <div className="w-full h-2 bg-slate-100 animate-pulse rounded"></div>
                    <div className="w-full h-2 bg-slate-100 animate-pulse rounded"></div>
                    <div className="w-full h-2 bg-slate-100 animate-pulse rounded"></div>
                  </div>
                </div>
              </div>
              <div className="relative bg-white rounded-lg overflow-hidden shadow border border-slate-100">
                <div className="w-full flex items-center py-4 px-5 border-none bg-transparent space-x-4">
                  <div className="w-full flex-grow flex-shrink space-y-2">
                    <div className="w-full h-3 bg-slate-100 animate-pulse rounded"></div>
                    <div className="w-full h-3 bg-slate-100 animate-pulse rounded"></div>
                  </div>
                  <div className="w-8 h-8 flex-grow-0 flex-shrink-0 ml-auto rounded-md  transition-all duration-200 overflow-hidden flex items-center justify-center bg-slate-100 animate-pulse"></div>
                </div>
              </div>
              <div className="relative bg-white rounded-lg overflow-hidden shadow border border-slate-100">
                <div className="w-full flex items-center py-4 px-5 border-none bg-transparent space-x-4">
                  <div className="w-full flex-grow flex-shrink space-y-2">
                    <div className="w-full h-3 bg-slate-100 animate-pulse rounded"></div>
                    <div className="w-full h-3 bg-slate-100 animate-pulse rounded"></div>
                  </div>
                  <div className="w-8 h-8 flex-grow-0 flex-shrink-0 ml-auto rounded-md  transition-all duration-200 overflow-hidden flex items-center justify-center bg-slate-100 animate-pulse"></div>
                </div>
              </div>
              <div className="relative bg-white rounded-lg overflow-hidden shadow border border-slate-100">
                <div className="w-full flex items-center py-4 px-5 border-none bg-transparent space-x-4">
                  <div className="w-full flex-grow flex-shrink space-y-2">
                    <div className="w-full h-3 bg-slate-100 animate-pulse rounded"></div>
                    <div className="w-full h-3 bg-slate-100 animate-pulse rounded"></div>
                  </div>
                  <div className="w-8 h-8 flex-grow-0 flex-shrink-0 ml-auto rounded-md  transition-all duration-200 overflow-hidden flex items-center justify-center bg-slate-100 animate-pulse"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FaqSkeleton;