import React from 'react';
import { classNames } from "../../helpers/classNames";
import { Link } from "react-router-dom";
import Image from "../elements/Image";

const ContactUs = ({ data }) => {
  return (
    <>
      <section className="relative overflow-hidden w-full py-20 bg-white z-0" id="contact">
        <div className="relative w-full px-4 sm:px-0 sm:max-w-xl md:max-w-2xl lg:max-w-4xl xl:max-w-6xl 2xl:max-w-7xl 3xl:max-w-9xl mx-auto">
          <div className="w-full max-w-7xl mx-auto">
            <div className="space-y-2">
              {data.secondaryTitle && <div className="text-base uppercase font-semibold text-mountain-meadow-500">{data.secondaryTitle}</div>}
              {data.title && <h2 className="font-MPlusRounded1c text-3xl xl:text-4xl 2xl:text-5xl text-cyprus-500">{data.title}</h2>}
              {data.subtitle && <div className="text-slate-500 text-sm">{data.subtitle}</div>}
            </div>
            <div className="grid grid-cols-1 gap-12 mt-10 lg:grid-cols-2">
              <div className="relative">
                {data.contact?.length > 0 &&
                  <div className="grid gap-12 grid-cols-2">
                    {data.contact?.map((item, index) => (
                      <div className="space-y-1" key={index}>
                        {item.icon &&
                          <div className="w-14 h-14 text-2xl flex items-center justify-center text-cyprus-500 rounded-full bg-pistachio-500 mb-4">
                            <i className={classNames("fa-fw", item.icon)}></i>
                          </div>
                        }
                        {item.name && <h4 className="text-sm font-bold text-cyprus-500">{item.name}</h4>}
                        {item.description && <div className="text-[13px] text-slate-400">{item.description}</div>}
                        {(item.type === "email" || item.type === "phone" || item.type === "link") ? (
                          <a
                            href={(item.type === "email") ? ("mailto:" + item.data) : (item.type === "phone") ? ("tel:" + item.data) : (item.type === "link") ? item.link : "/"}
                            target={item.type === "link" ? "_blank" : "_self"}
                            rel={item.type === "link" ? "noreferrer" : ""}
                            className="text-sm text-pistachio-500 font-semibold"
                          >{item.data}</a>
                        ) : (
                          <div className="text-sm text-pistachio-500 font-semibold">{item.data}</div>
                        )}
                      </div>
                    ))}
                    {data.socialMenu &&
                      <>
                        {data.socialMenu?.length > 0 &&
                          <div className="space-y-1">
                            {data.socialMenuTitle && <h4 className="text-sm font-bold text-cyprus-500 mb-3">{data.socialMenuTitle}</h4>}
                            <div className="space-y-1">
                              {data.socialMenu?.map((item) => (
                                <a
                                  key={item._id}
                                  href={item.link}
                                  target="_blank"
                                  rel="noreferrer"
                                  className="flex items-center py-1 space-x-4 group"
                                >
                                  <div className="flex items-center justify-center w-8 h-8 bg-pistachio-500 rounded-md text-white transition-all duration-200 group-hover:bg-mountain-meadow-500">
                                    <i className={classNames("fa-brands fa-fw", item.icon)}></i>
                                  </div>
                                  <div className="text-sm font-semibold text-cyprus-500 transition-all duration-200 group-hover:text-mountain-meadow-500">{item.name}</div>
                                </a>
                              ))}
                            </div>
                          </div>
                        }
                      </>
                    }
                    {data.appDownload &&
                      <>
                        {data.appDownload?.menu?.length > 0 &&
                          <div className="space-y-1">
                            {data.socialMenuTitle && <h4 className="text-sm font-bold text-cyprus-500 mb-3">{data.appDownload?.title}</h4>}

                            <div className="relative space-y-3 sm:space-y-4">
                              {data.appDownload?.menu?.map((item) => (
                                <div className="flex" key={item._id}>
                                  <Link to={item.link} rel="noreferrer" target="_blank" className="text-white text-xs xl:text-sm transition-all duration-200 hover:text-pistachio-500">
                                    <div className="flex h-10">
                                      <Image src={item.image} alt={item.name} width={'100%'} height={'100%'} effect={'blur'} classes={'object-contain lg:object-left'} />
                                    </div>
                                  </Link>
                                </div>
                              ))}
                            </div>
                          </div>
                        }
                      </>
                    }
                  </div>
                }
              </div>
              <div className="p-4 py-6 rounded-3xl bg-white shadow-lg border border-slate-50 md:p-8">
                {data.formTitle && <h2 className="text-xl font-semibold text-mountain-meadow-500 mb-4">{data.formTitle}</h2>}
                {data.contactForm && data.contactForm}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ContactUs;